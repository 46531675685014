@import './tenant_variable.scss';
@import '../fonts/Roboto/stylesheet.css';
@font-face {
	font-family: 'Material Icons';
	font-style: normal;
	font-weight: 400;
	src: url('../fonts/material/MaterialIconsOutlined-Regular.woff'); /* For IE6-8 */
	src: local('Material Icons'), local('MaterialIcons-Regular'),
		url('../fonts/material/MaterialIconsOutlined-Regular.woff') format('woff');
	font-display: swap;
}
/* Global Styles */
html,
body {
	height: 100%;
}
body {
	margin: 0;
	font-family: Roboto, 'Helvetica Neue', sans-serif;

	// For breaking word
	overflow-wrap: break-word;
	word-wrap: break-word;
	word-break: break-word;
	-ms-hyphens: auto;
	-moz-hyphens: auto;
	-webkit-hyphens: auto;
	hyphens: auto;
}
img {
	max-width: 100%;
	height: auto;
	vertical-align: top;
	border: none;
}
app-sv-progress-spinner {
	position: absolute;
	width: 100%;
	height: 100%;
	overflow: hidden;
	background: #fff;
	z-index: 9999;
}
.mb-64 {
	margin-bottom: 64px !important;
}

.mt-64 {
	margin-top: 64px !important;
}

.mt-8 {
	margin-top: 8px !important;
}

.pb-48 {
	padding-bottom: 48px !important;
}

.pb-64 {
	padding-bottom: 64px !important;
}

.pt-64 {
	padding-top: 64px !important;
}
.flex {
	display: flex !important;
}
.align-center {
	align-items: center !important;
}
body .mat-expansion-panel {
	background: white;
	color: #000000de;
}
html,
body,
div,
span,
p,
h1,
h2,
h3,
h4,
h5,
h6,
input,
select,
a,
b {
	margin: 0;
	padding: 0;
	font-weight: inherit;
}
// body .mat-flat-button:not([class*='mat-elevation-z']) {
// 	border: 1px solid #589c2e;
// }
.mat-form-field-appearance-outline .mat-form-field-infix {
	padding: 6.25px 0 14.25px;
}
.mt-32 {
	margin-top: 32px !important;
}
.mat-checkbox-frame {
	border-color: #0000008a;
}
.mat-checkbox-checked.mat-primary .mat-checkbox-ripple .mat-ripple-element {
	background-color: #246c0e !important;
}

.mat-checkbox-checked.mat-primary .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background {
	background-color: #246c0e !important;
}

.mat-checkbox-checked.mat-primary .mat-checkbox-background {
	background-color: #246c0e !important;
}
.mat-checkbox-checkmark-path {
	stroke: #ffffff !important;
}
// .mat-flat-button.mat-primary {
// 	background-color: #246c0e;
// }
.mat-flat-button.mat-button-disabled.mat-button-disabled,
body.theme-ecap .mat-stroked-button.mat-button-disabled.mat-button-disabled {
	background-color: #bdbdbd !important;
	color: #fff;
	border: none;
}
.mat-snack-bar-container {
	box-shadow: none !important;
	background: transparent !important;
}
.mat-flat-button {
	min-width: 120px !important;
	line-height: 20px !important;
}
.section-padding {
	padding: 45px 0;
}

.section-padding.mobile-display {
	padding: 24px 16px;
}

a {
	color: #d0ac6d;
	text-decoration: none;
}

a {
	cursor: pointer;
	color: #3c6321 !important;
	&:hover {
		text-decoration: underline !important;
	}
}

#mat-error-0,
#mat-error-1,
#mat-error-2,
#mat-error-3 {
	color: $primary-error-color !important;
	font-size: 12px !important;
	font-weight: 400 !important;
}

.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
	color: $primary-error-color !important;
}

@media (max-width: 1440px) {
	.section-padding {
		padding: 45px 50px;
	}
}

@media (max-width: 960px) {
	.top-bg {
		height: 250px;
	}
	.section-padding {
		padding: 32px 16px;
	}
	.footer-bottom {
		height: 32px !important;
	}
}

@import './sv_styles.scss';
.cc-revoke {
	display: none;
}

.ml-24 {
	margin-right: 24px;
}

.mr-16 {
	margin-right: 16px;
}

.cc-btn {
	color: white !important;
	display: block;
	width: 100%;
}
@media screen and (max-width: 900px) {
	.cc-color-override-1040732145.cc-window {
		flex-direction: column;
	}

	.cc-window.cc-banner {
		align-items: unset;
	}
}
.cc-btn.cc-allow {
	background-color: $primary-700 !important;
	&:hover {
		background-color: lighten($primary-700, 5%) !important;
	}
}
.cc-window.cc-floating .cc-compliance {
	display: flex;
	flex-direction: column;
}
// body {
// 	height: 100vh;
// }

@media screen and (min-width: 285px) {
	.cc-window.cc-floating .cc-compliance {
		display: flex;
		flex-direction: row;
	}
}

@media screen and (min-width: 500px) {
	.cc-window.cc-floating {
		flex-direction: row;
	}
	.cc-theme-edgeless.cc-window {
		padding: 0.5em 1em;
	}
	.cc-banner.cc-theme-edgeless .cc-message {
		margin-left: unset;
	}
}
.cc-window.cc-floating {
	padding: 0em;
	max-width: unset;
	flex-direction: column;
}

.cc-floating.cc-theme-edgeless .cc-message {
	margin: 1em;
}
.cc-bottom {
	bottom: 0;
}

::-webkit-scrollbar {
	width: 8px;
}

::-webkit-scrollbar-track {
	background: transparent;
}

::-webkit-scrollbar-thumb {
	background: $neutral-60;
	border: none;
	border-radius: 20px;
}

.radio-button-start-align {
	.mat-radio-label {
		align-items: start !important;
	}
}

.cc-window {
	z-index: 3;
}

@media screen and (max-width: 599px) {
	.ingredient-view-modal .mat-dialog-container {
		padding: 12px !important;
	}
}

.nowrap-text {
	white-space: nowrap;
}
