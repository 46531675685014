h1 {
	font-size: 56px;
	font-weight: bold;
	line-height: 125%;
	letter-spacing: -2%;
}

h2 {
	font-size: 48px;
	line-height: 125%;
	letter-spacing: -2%;
}

h3 {
	font-size: 36px;
	line-height: 125%;
	letter-spacing: 0%;
}

h4 {
	font-size: 28px;
	line-height: 140%;
	letter-spacing: 0%;
}

h5 {
	font-size: 24px;
	line-height: 140%;
	letter-spacing: 0%;
}

h6 {
	font-size: 20px;
	line-height: 140%;
	letter-spacing: 0%;
}

p {
	font-size: 16px;
	line-height: 145%;
	letter-spacing: 0%;
}

.ql-size-small {
	font-size: 14px;
}
.ql-size-large {
	font-size: 16px;
}
.ql-size-huge {
	font-size: 18px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
	h1 {
		font-size: 44px;
	}
	h2 {
		font-size: 40px;
	}
	h3 {
		font-size: 32px;
	}
	h4 {
		font-size: 28px;
	}
	h5 {
		font-size: 24px;
	}
	h6 {
		font-size: 20px;
	}

	.ql-size-large {
		font-size: 20px;
	}
	.ql-size-huge {
		font-size: 28px;
	}
}
