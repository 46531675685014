@import '~@angular/material/theming';
.mat-checkbox-layout {
	white-space: normal !important;
}
@include mat-core();

$mat-arc-primary: (
	900: #16270c,
	800: #2d4f17,
	700: #437623,
	600: #599e2e,
	500: #589c2e,
	400: #8cd161,
	300: #a9dc89,
	200: #c6e8b0,
	100: #e2f3d8,
	95: #ebf3e6,
	90: #cde1c0,
	80: #acce97,
	70: #8aba6d,
	60: #71ab4d,
	50: #589c2e,
	40: #509429,
	30: #478a23,
	20: #3d801d,
	25: #246c0e,
	10: #2d6e12,
	contrast: (
		50: #000000,
		100: #000000,
		200: #000000,
		300: #000000,
		400: #ffffff,
		500: #ffffff,
		600: #ffffff,
		700: #ffffff,
		800: #ffffff,
		900: #ffffff,
		A100: #000000,
		A200: #000000,
		A400: #000000,
		A700: #000000,
	),
);

$my-app-primary: mat-palette($mat-arc-primary);
$my-app-accent: mat-palette($mat-pink, 500, 900, A100);
$my-app-warn: mat-palette($mat-deep-orange);
$my-app-theme: mat-light-theme($my-app-primary, $my-app-accent, $my-app-warn);
@include angular-material-theme($my-app-theme);
