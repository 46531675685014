$primary-background-color: #ffff;
$primary-text-color: #dadada;
$primary-error-color: #b00020;
$primary-text-high-emphasis-color: #333333;
$primary-text-medium-emphasis-color: #636363;

// Sv Basic Color
$sv-black-color: #000000;
$sv-light-gray-color: #9ea1a3;
$sv-light-gray-50: #e9e9e9;
$sv-light-gray-100: #d9d9d9;
$sv-light-gray-150: #7b7b7b;
$sv-dark-gray-color: #565655;

// SV-primary
$primary-900: #16270c;
$primary-800: #2d4f17;
$primary-700: #437623;
$primary-600: #599e2e;
$primary-500: #589c2e;
$primary-400: #8cd161;
$primary-300: #a9dc89;
$primary-200: #c6e8b0;
$primary-100: #e2f3d8;
$primary-95: #ebf3e6;
$primary-90: #cde1c0;
$primary-80: #acce97;
$primary-70: #8aba6d;
$primary-60: #71ab4d;
$primary-50: #589c2e;
$primary-40: #509429;
$primary-30: #478a23;
$primary-20: #3d801d;
$primary-10: #2d6e12;
// $primary-50: #f1f9eb;
$primary-25: #246c0e;

.primary-500 {
	background-color: $primary-500 !important;
	color: $primary-text-high-emphasis-color;
}
.primary-600 {
	background-color: $primary-600 !important;
	color: $primary-text-high-emphasis-color;
}
.primary-700 {
	background-color: $primary-700 !important;
	color: $primary-text-high-emphasis-color;
}
.primary-800 {
	background-color: $primary-800 !important;
	color: $primary-text-high-emphasis-color;
}
.primary-900 {
	background-color: $primary-900 !important;
	color: $primary-text-color;
}
.primary-400 {
	background-color: $primary-400 !important;
	color: $primary-text-high-emphasis-color;
}
.primary-300 {
	background-color: $primary-300 !important;
	color: $primary-text-high-emphasis-color;
}
.primary-200 {
	background-color: $primary-200 !important;
	color: $primary-text-high-emphasis-color;
}
.primary-100 {
	background-color: $primary-100 !important;
	color: $primary-text-high-emphasis-color;
}
.primary-50 {
	background-color: $primary-50 !important;
	color: $primary-text-high-emphasis-color;
}

// SV- Neutral
$neutral-900: #212121;
$neutral-800: #424242;
$neutral-700: #616161;
$neutral-600: #757575;
$neutral-500: #9e9e9e;
$neutral-400: #bdbdbd;
$neutral-300: #e0e0e0;
$neutral-200: #eeeeee;
// $neutral-100: #f5f5f5;
// $neutral-50: #ffffff;
$neutral-100: #ffffff;
$neutral-95: #fafafa;
$neutral-90: #f5f5f5;
$neutral-80: #e9e9e9;
$neutral-70: #d9d9d9;
$neutral-60: #c4c4c4;
$neutral-50: #9d9d9d;
$neutral-40: #7b7b7b;
$neutral-30: #555555;
$neutral-20: #434343;
$neutral-10: #262626;
$neutral-0: #000000;

//status color
$sv-error: #ff4646;
$sv-success: #00ae5b;
$sv-warning: #e86826;
$sv-disabled: #e9e9e9;
$sv-alert: #ffcc00;

//status bg color
$sv-draft-bg: #fff8ed;

//border color
$border-secondary-200: #a6a8a9;

// sv accent colors
$accent-yellow: #ffd962;
$accent-orange: #f3953f;
$accent-red: #df3425;
$accent-blue: #312d5a;
$accent-chocolate: #523c2c;
$accent-brown: #c4a888;
$accent-walnut: #dead68;
$accent-pinkish-red: #dead68;

// arc-accent

$accent-background-color: #ffffff;
$accent-text-color: #dadada;
$accent-text-high-emphasis-color: #333333;
$accent-text-medium-emphasis-color: #636363;

// arc-accent
$accent-500: #4d5052;
$accent-600: #303233;
$accent-700: #252627;
$accent-800: #1e1f20;
$accent-900: #131314;
$accent-400: #696d70;
$accent-300: #909599;
$accent-200: #b6bdc2;
$accent-100: #d8e1e8;
$accent-50: #e7edf1;

$accent-A100: #ef6e6e;
$accent-A200: #ea4040;
$accent-A400: #f60000;
$accent-A700: #dd0000;

.accent-500 {
	background-color: $accent-500 !important;
	color: $accent-text-high-emphasis-color;
}
.accent-600 {
	background-color: $accent-600 !important;
	color: $accent-text-high-emphasis-color;
}
.accent-700 {
	background-color: $accent-700 !important;
	color: $accent-text-high-emphasis-color;
}
.accent-800 {
	background-color: $accent-800 !important;
	color: #ffffff;
}
.accent-900 {
	background-color: $accent-900 !important;
	color: #ffffff;
}
.accent-400 {
	background-color: $accent-400 !important;
	color: $accent-text-high-emphasis-color;
}
.accent-300 {
	background-color: $accent-300 !important;
	color: $accent-text-high-emphasis-color;
}
.accent-200 {
	background-color: $accent-200 !important;
	color: $accent-text-high-emphasis-color;
}
.accent-100 {
	background-color: $accent-100 !important;
	color: $accent-text-high-emphasis-color;
}
.accent-50 {
	background-color: $accent-50 !important;
	color: $accent-text-high-emphasis-color;
}

// Surface / card

$surface-background-color: #ffffff;
$surface-text-color: #999999;
$disable-text-color: #d9dbdc;

$danger-icon-color: #b00020;
$text-color: $primary-text-high-emphasis-color;
$text-color-light: $primary-text-medium-emphasis-color;

$btn-gradiant: #5b2b14;

$table-row-color: #eaeef1;
$side-nav-select-background: #eaeef1;

/* added from arc mixin */
$primary-A100: #f0f6fc;
$input-background: #f9f9f9;
$status-inactive: #c7c8cc;
$status-success: #00ae5b;
$status-background: #ebf3e6;

// slider color
$dot-background: #bbb;
$slider-text: #f2f2f2;
$active-dot: #717171;
